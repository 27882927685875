.App {
}

body {
  font-family: var(--font-family);
  font-weight: normal;
  background: var(--surface-ground);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  background-color: #282c34;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.card {
  background: #282c34;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.p-fieldset {
}

.p-fieldset-legend {
}



.social-toolbar {
  border-radius: 3rem;

  background-image: linear-gradient(to right, var(--bluegray-500), var(--bluegray-800));
  border: 0px;
}

.p-toolbar-group-start {
  padding-left: 3rem;
}

.p-toolbar-group-center {
  gap: 10px;
}

.p-toolbar-group-end {
  padding-right: 3rem;
}
